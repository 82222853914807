.profile-listCollection {

    h3 {
        margin-top: 32px;
        margin-bottom: 4px;
    }

    ul {
        list-style: none;
        padding: 0;
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        margin: 0px;
        background: #373b49;
        color: #fff;
        border-radius: 5px;
        padding: 1.25em;

        @media screen and (max-width:620px) {
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1;
        }

        li {
            font-size: 16px;
            font-weight: 300;
            border-bottom: 1px solid #ffffff1c;
            padding: 2px 0;

            &:before {
                content: '-';
                display: inline-block;
                padding-right: 10px;
                margin-left: 0px;
                color: #00afef;
            }
        }
    }
}
