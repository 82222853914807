@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600&display=swap');

body {
    margin: 0;
    font-family: 'Hind', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .App {
        background: #525659;
        color: #fff;

        .two-column {
            width: 100%;
            max-width: 980px;
            margin: auto;
            overflow: hidden;


            .col {
                display: flex;
                flex-direction: column;
                flex-basis: 100%;
                flex: 1;
                padding: 2em;
                position: relative;
                -webkit-box-shadow: 0px 6px 10px 1px rgba(0,0,0,0.43);
                -moz-box-shadow: 0px 6px 10px 1px rgba(0,0,0,0.43);
                box-shadow: 0px 6px 10px 1px rgba(0,0,0,0.43);
            }

            aside {
                background: #373b49;
                z-index: 10;
                animation: slideUp 0.7s ease-in-out forwards;
            }

            main {
                background: #fff;
                color: #000;
                z-index: 1;
                opacity: 0;
                animation-delay: 0.7s;
                animation-name: slideRight;
                animation-duration: .8s;
                animation-fill-mode: forwards;

                .heading {
                    border-bottom: 3px solid #000;
                    text-transform: uppercase;

                    h2 {
                        margin: 0 0 8px 0;
                    }
                }
            }
        }
    }

    @media screen and (min-width:992px) {
        .App {
            padding: 4em 0;

            .two-column {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;


                aside {
                    max-width: 300px;
                    margin: 0px;
                }
            }
        }
    }

    @media screen and (max-width:991px) {
        .App {
            .two-column {

                .col {
                    padding: 2em;
                }

                aside {
                    margin: 1.5em 1.5em 0 1.5em;
                    box-shadow: 0px -1px 2px 2px rgb(0 0 0 / 40%) !important;
                }

                main {
                    margin: 0em 1.5em 1.5em 1.5em;
                    opacity: 1 !important;
                    animation: slideUp 0.7s ease-in-out forwards !important;
                }
            }
        }
    }

    @media screen and (max-width:768px) {
    }

    .margin-bottom-48 {
        margin-bottom: 48px;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100vh);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(-100vw);
    }

    30% {
        opacity: 1;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}
