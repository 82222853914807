
.profile-name {
    text-align: center;
    margin-bottom: 32px;

    h2, h3 {
        margin: 0px;
        text-transform: uppercase;
    }

    h2 {
        margin-top: 8px;
        font-size: 32px;
    }

    h3 {
        color: #00b0f0;
        font-size: 24px;
    }
}
