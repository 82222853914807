.profile-image {

    img {
        display: block;
        margin: auto;
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 32px;
        transform: rotateZ(8deg);
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
}
