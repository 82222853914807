.profile-experiences {
    margin-bottom: 32px;

    .profile-experiences-company {
        border-bottom: 1px solid #00000038;
        padding-bottom: 24px;

        label {
            font-weight: 700;
            font-size: 16px;
            color: #000;
        }

        .profile-experiences-company-heading {
            padding-top: 20px;
            margin-bottom: 20px;

            p, a {
                display: inline-block;
                margin-top: 2px;
                margin-bottom: 2px;
                font-size: 16px;
            }


            img {
                width: 100%;
                display: block;
                max-width: 110px;
                margin: 12px 0;
            }

            a {
                color: #15a5f0;
                &:hover {
                    color: #373b49;
                }
            }
        }

        .profile-experiences-details {
            margin-top: 0px;
        }
    }
}
